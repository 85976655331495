<template>
  <div class="app-container">
    <div class="add-container">
      <div class="add-title">预约设置</div>
    </div>
    <el-form label-width="150px">
      <el-form-item label="可预约范围：">
        <el-row type="flex" align="center">
          <el-form-item style="margin:0" label-width="0">
            <el-input-number v-model="postData.ahead_start" placeholder="起"></el-input-number>
          </el-form-item>
          <span style="line-height:40px;margin:0 20px">至</span>
          <el-form-item style="margin:0" label-width="0">
            <el-input-number v-model="postData.ahead_end" placeholder="止"></el-input-number> 小时
          </el-form-item>
        </el-row>
      </el-form-item>
      <el-form-item label="预约时长：">
        <el-input-number placeholder="输入分钟数" :min="10" v-model="postData.duration" :max="1440" @change="durationChange"></el-input-number> 分
      </el-form-item>
      <el-form-item label="智能跳过节假日：">
        <el-checkbox v-model="postData.skip_holiday" disabled :true-label="1" :false-label="0">跳过节假日</el-checkbox>
      </el-form-item>
      <template v-for="(item, index) in weeks">
        <el-form-item style="margin-bottom:0" class="form-item">
          <div slot="label" class="form-item-label">
            <label class="el-form-item__label" style="width: 150px;">可预约时间段({{item.title}})：</label>
            <p>({{item.time}})</p>
          </div>
          <el-checkbox-group v-model="postData.config[index + 1].around_arr">
            <el-checkbox v-for="item in timeRange" :key="item.key" :label="item.key"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="postData.config[index + 1].repetition" :true-label="1" :false-label="0">每周重复</el-checkbox>
        </el-form-item>
      </template>
      <el-form-item>
        <el-button @click="doReset">重置</el-button>
        <el-button type="primary" @click="doSave">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script type="text/javascript">
import api from '@/api/room'
const postData = {
  duration: 30,
  ahead_start: 24,
  ahead_end: 72,
  skip_holiday: 1,
  config: {
    1: {
      repetition: 1,
      around_arr: [],
      time: ''
    },
    2: {
      repetition: 1,
      around_arr: [],
      time: ''
    },
    3: {
      repetition: 1,
      around_arr: [],
      time: ''
    },
    4: {
      repetition: 1,
      around_arr: [],
      time: ''
    },
    5: {
      repetition: 1,
      around_arr: [],
      time: ''
    },
    6: {
      repetition: 1,
      around_arr: [],
      time: ''
    },
    7: {
      repetition: 1,
      around_arr: [],
      time: ''
    },
  }
}
export default {
  data() {
    let weeks = []
    const weekMap = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth()
    const firstDate = new Date(year, month, date.getDate() - date.getDay())
    const firstYear = firstDate.getFullYear()
    const firstMonth = firstDate.getMonth()
    const firstDay = firstDate.getDate()

    function formatDate(date) {
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      return [year, month > 9 ? month : ('0' + month), day > 9 ? day : ('0' + day)].join('-')
    }

    for (let i = 0; i < 7; i++) {
      weeks.push({
        title: weekMap[i],
        time: formatDate(new Date(firstYear, firstMonth, firstDay + i + 1))
      })
    }
    return {
      weeks,
      postData: { ...postData },
    }
  },
  computed: {
    timeRange() {
      const start = 8
      const end = 20
      const times = Math.floor(1440 / this.postData.duration)
      let arr = []
      for (let i = 0; i < times; i++) {
        const startTime = this.postData.duration * i
        const endTime = this.postData.duration * (i + 1)
        if (startTime < start * 60 || endTime > end * 60) {
          continue;
        }
        const obj = {
          start: this.getTime(startTime),
          end: this.getTime(endTime)
        }
        arr.push({
          ...obj,
          key: `${obj.start} ~ ${obj.end}`
        })
      }
      return arr
    }
  },
  created() {
    api.getConfig()
    .then(res => {
      let ahead_arr = res.ahead_time.split('-')
      this.postData = {
        ...res,
        ahead_start: Number(ahead_arr[0]),
        ahead_end: Number(ahead_arr[1]),
      }
    })
  },
  methods: {
    durationChange() {
      this.weeks.map((v, index) => {
        this.postData.config[index + 1].around_arr = []
      })
    },
    getTime(time) {
      let hour = Math.floor(time / 60)
      let min = time % 60
      return this.pad(hour) + ':' + this.pad(min)
    },
    pad(num) {
      return `0${num}`.substr(-2)
    },
    doReset() {
      this.postData = { ...postData }
      this.$message.success('重置成功')
    },
    doSave() {
      // 格式化处理数据
      this.postData.ahead_time = `${this.postData.ahead_start}-${this.postData.ahead_end}`
      // 处理time
      this.weeks.map((v, index) => {
        this.postData.config[index + 1].time = v.time
        // 处理时间段信息
        this.postData.config[index + 1].around = this.postData.config[index + 1].around_arr.map(str => {
          let arr = str.split(' ~ ')
          return {
            start: arr[0],
            end: arr[1]
          }
        })
      })
      ;(this.postData.id > 0 ? api.updateConfig(this.postData) : api.addConfig(this.postData))
      .then(res => {
        this.$message.success('保存成功')
      })
    },
  }
}
</script>

<style lang="less" scoped>
.form-item {
  /deep/ .el-checkbox__label {
    width: 105px;
  }
  &-label {
    line-height: 1;
    p {
      text-align: center;
      margin: 0;
    }
  }
}
</style>
